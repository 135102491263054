.subsidiary-status {
  display: flex;
  flex-direction: row;
  .subsidiary {
    height: 31px !important;
    border: none !important;
    box-shadow: none !important;
    float: left;
    width: 10%;
    margin-top: 11px;
  }
}

.not-listed-status {
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  .not-listed {
    height: 31px !important;
    border: none !important;
    box-shadow: none !important;
    float: left;
    width: 10%;
  }
}
.form-control:focus:focus {
  border-color: none !important;
}
.view_btn {
  background: #efd75d;
  font-size: 17px;
  border: none !important;
  color: black !important;
  font-weight: 600 !important;
  padding: 7px !important;
}
