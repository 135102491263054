.user-container {
  .addUser-btn {
    background: #efd75d;
    border: none;
    color: black;
    font-weight: 800;
    width: 20%;
    border-radius: 1px;
    height: 52px;
    margin-right: 85px;
  }
  .btn-primary {
    background: #efd75d;
    font-size: 17px;
  }
  .select-company {
    margin-left: 15px;
    position: absolute;
    margin-top: 9px;
  }
  .multiSelect {
    width: 100%;
    margin-left: 0px !important;
    .chip-body {
      height: 33px;
      min-width: 55px;
      background-color: #efd75d;
      color: black;
    }
    .container input:checked ~ .checkmark {
      background-color: #efd75d;
      color: black;
      padding-top: 5px;
    }
  }
  .list-result {
    .container {
      padding-left: 30px;
    }
  }
}
.input-box {
  height: 64px !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  color: black !important;
  border: 1px solid #e6e6e6 !important;
  opacity: 1;
  border-radius: 0px !important;
}
.sc-jTzLTM {
  font-size: 20px;
  color: black;
  font-weight: 700;
}
.react-data-table--cell-content {
  font-size: 16px;
}

.country-flag {
  height: 64px !important;
  font-weight: 500 !important;
  color: black !important;
  opacity: 1;
  border-radius: 0px !important;
  color: #a3a3a3 !important;
  font-size: 20px !important;
  padding-left: 12px !important;
  cursor: default !important;
}
.flag-select {
  width: 96%;
  height: 64px !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  color: black !important;
  border: 1px solid #e6e6e6 !important;
  opacity: 1;
  border-radius: 0px !important;
  margin-left: 12px;
  padding-top: 11px;
}

.person-number-validation{
  font-size: 17px !important;
  color: black !important;
  margin-left: 17px;
  margin-top: -33px;
}
.menu-list{
  font-size: 10px !important;
}