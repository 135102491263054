

.agendCtrls-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agendCtrls-event-input {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    z-index: 3;
}

.agendCtrls-remove-button{
  margin-left: 8px;
}

.agendCtrls-timePicker-wrapper{
  display: flex;
  flex-direction: row;
  padding-top: 8px;
}

.agendCtrls-label-wrapper{
  display: flex;
}
.agendCtrls-radio-buttons {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    margin-left: 8px;
}
.agendCtrls-radio-button {
  width: 100%;
      height: 100%;
      background: none;
      border: none;
      border-radius: 50%;

}
.agendCtrls-radio-button--checked {
  width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
      background: none;
      border: none;
      border-radius: 50%;
}
.agendCtrls-radio-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.agendCtrls-time-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.agendCtrls-time-picker label {
  font-size: 15px;
  font-weight: 600;
  padding: 8px;
}

.column {
  width: 50% !important;
}
.row-val {
  /* background-color: green !important; */
  height: 40px;
  width: 100%;
  align-items: center;
  /* vertical-align: middle; */
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 20px;
}

.row-label {
  height: 100% !important;
  align-self: center !important;
  text-align: center;
  line-height: 40px;
  /* width: 100%; */
}

.row-title {
  /* background-color: red !important; */
  height: 40px;
  width: 100%;
  align-items: center;
  /* vertical-align: middle; */
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 20px;
  text-align: center;
}

.agendCtrls-label-inline {
  display: flex;
      flex-direction: column;
      width: 70% !important;
}

.agendCtrls-label-inline label{
  font-size: 15px;
  font-weight: 600;
  align-self: flex-start;
  padding: 8px;
}

.class1num {
  align-items: center;
}


@media screen and (max-width: 639px) {
  .agendCtrls-timePicker-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: column;
    padding-top: 8px;
}

.agendCtrls-label-inline {
       display: flex;
      flex-direction: column;
      width: 100%;
}
}
