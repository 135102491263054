.nav-link {
  color: white !important;
  background: black !important;
}
.nav-wrapper {
  background: black !important;
  // height: calc(90vh - -0.25rem - 1px) !important;
  height: calc(100vh) !important;
  .logo {
    height: 90px;
    width: 90px;
  }
}

.nav-drawer-mobile
{
  .nav-wrapper {
    position: absolute;
    left: 15%;
  }
}
.main-navbar {
  box-shadow: none !important;
}
.main-navbar .navbar {
  height: 1.75rem !important;
}
.sidebar-title {
  font-size: 19px;
  // font-family: "Avenir Next";
  letter-spacing: 0;
  opacity: 1;
}
.logged-user {
  margin: 10px;
  padding: 15px;
  // background-color: 'red';
  height: 30px;
  color: #efd75d !important;
}

.logged-user1 {
  margin-left: 10px;
  padding-left: 15px;
  // background-color: 'red';
  height: 100px;
  color: #efd75d !important;
}

.logged-user-label {
  color: #efd75d !important;
}
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item:hover,
.main-sidebar .nav .nav-item .nav-link:hover {
  box-shadow: none !important;
  background-color: #fbfbfb;
  color: #efd75d !important;
}

@media all and (-ms-high-contrast: none) {
  .logout-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }
}

@supports (-ms-ime-align:auto) {
  .logout-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }
}
.logout-container {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  span {
    cursor: pointer;
  }
}
.version {
  color: white;
  float: left;
  width: 100%;
  display: flex;
  margin-top: 0px !important;
  cursor: default;
  margin-bottom: 0em !important;
}
.nav--no-borders {
  margin-top: -27px;
  padding-bottom: 23px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(54, 56, 58);
}
