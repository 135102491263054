.modal-wrapper{

      display: -ms-flexbox;
      display: flex !important;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      z-index: 10;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      position: fixed;
      -ms-flex-align: center;
      align-items: center;
      top: 0px;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255 ,255 ,255 ,0.5);
}


.modal{
  z-index: 102;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding: 15px;
      box-shadow: rgba(0,0,0,0.2) 0 2px 6px;
      background: #FFF;
}

.modal-nude{
  z-index: 102;
      display: flex !important;
      flex-direction: column;
      justify-content: center;

}

.modal-close {
    margin-left: 97%;
    position: relative;
    width: 18px;
    height: 18px;
    line-height: 1.3;
    text-align: center;
    font-size: 14px;
    /* border: 1px solid; */
    background: #222;
    color: #FFF;
    /* font-weight: 600; */
    border-radius: 50%;
    cursor: pointer;
}

.hide-modal{

  margin-right: 5px;
  margin-top: 5px;
}



.modal-title{
  margin: 5px;
font-size: 20px;
}
