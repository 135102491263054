.personal-container {
  background: #ffffff;
  .save-btn {
    background: #efd75d 0% 0% no-repeat padding-box;
    opacity: 1;
    border: none;
    color: black;
    width: 261px;
    border-radius: 1px;
    height: 64px;
    font-weight: 700 !important;
    font-size: 20px !important;
  }
  .btn-primary:hover {
    background: #efd75d 0% 0% no-repeat padding-box !important;
    box-shadow: none !important;
  }
}
.main-content {
  height: calc(100vh - -0.25rem - 1px) !important;
  background: white;
}
.title-header {
  font-family: "Avenir Next";
  letter-spacing: 0;
  color: #292929;
  opacity: 1;
  margin-top: 10px;
}
