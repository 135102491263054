.dashboard{
    .dashboard-btn{
        background: #61819e;
        border: none;
        width: 250px;
        height: 58px;
        font-size: 17px;
    }
    .count{
        font-size: 40px !important;
    }
    .stats-small__label {
        font-size: 1rem !important;
        letter-spacing: 0rem;
    }
    .stats-small {
        min-height: 10.5rem;
    }
    .light-green{color: #85c96b !important};
    .orange-clr{color:#f47b1f !important};
    .light-blue{color:#59bde8 !important}
    .light-grey{color:#bdbdbd}
}