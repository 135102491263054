
#RBS-Scheduler-root {
  display: block !important;
  overflow: auto !important;
  width: 100% !important;
}
.scheduler-view {
  width: 100% !important;
}
.resource-view {
  div {
    width: 100% !important;
  }
}
.ant-popover {
  z-index: 10000;
}
body{
    overflow-x: hidden;
}
.scheduled-container {
  padding-right: 45px;
  padding-left: 45px;

}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1071 !important;  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  // background-color: rgb(0,0,0.5); /* Fallback color */
  background-color: rgba(0,0,0,0.4) !important; /* Black w/ opacity */
  // background-color: rgba(10,255,0,0.4) !important; /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content1 {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

@media (min-width: 320px) and (max-width: 587px) {
  .modal-content1 {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 100%; /* Could be more or less, depending on screen size */
  }
}
/* The Close Button */
.close {
  color: #aaa;
  float: left;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.picker1 {
  height: 64px !important;
  // padding: 0.4375rem 0.75rem !important;
  z-index: 999999 !important
}