.location-label {
  font-size: 24px;
  color: black;
  font: Bold 24px/33px Avenir Next;
  color: #141414;
  opacity: 1;
}

.MuiSvgIcon-root:checked {
  background: #efd75d !important;
  height: 56px !important;
}
.MuiCheckbox-root {
  color: #efd75d !important;
  border-radius: 0%;
}

.btn-1{
  background: #efd75d;
    font-size: 17px;
    width: 50% !important;
    background: #efd75d;
    border: none;
    color: black;
    font-weight: 800;
    border-radius: 1px;
    height: 46px;
    margin-right: 85px;
}
.outer1
{
    margin: 10px;
    width: 100%;
    // height: 80px !important;
    // background-color: blue;
    // text-align: center;
}

.css-yk16xz-control {
  border: hsl(0, 5%, 89%) !important;
  border-width: 1px;
  border-color: black !important;
}
.inner1
{
    display: inline-block;
    vertical-align: text-top;
    margin: 0 auto;
  // background-color:purple;
  // display: inline-block;
  // // padding: 5px;
  width: 50%;
}
.select-view {
  float: left;
  border: medium;
  background-color: gray;
  border-radius: 5px;
  border-color: red;
  // background-color: red;
  align-self: flex-start;
  align-items: flex-start;
  width: 80%;
  // height: 70px;
  // padding-top: 25px;
  margin: 5px;
  padding: 1px;
}
.MuiSvgIcon-root {
  height: 56px !important;
  width: 75px !important;
  margin-top: -13px;
  margin-left: -17px;
}
.sc-bZQynM {
  input {
    height: 64px !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    color: black !important;
    border: 1px solid #e6e6e6 !important;
    opacity: 1;
    border-radius: 0px !important;
  }
}
.dropdown {
  :hover {
    background: white !important;
  }
  button {
    margin-top: 10px;
    width: 100%;
    height: 64px;
    // background: #efd75d !important;
    background: white;
    border: 1px solid #e1e5eb;
    color: black;
    font-size: 24px;
    font-weight: 600;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.expandable-listview_outerDiv {
  height: 400px;
  overflow-y: auto;
  outline: 1px dashed blue;
  width: 400px;
}

.expandable-listview_ul {
  margin: 0px;
  list-style-type: none;
  padding: 0;
}

.Collapsible {
  width: 85%;
  border: 1px solid #e6e6e6 !important;
  opacity: 1;
}

.location-container {
  background: #ffffff;
  .dropdown-item {
    border: none !important;
  }
  .save-btn {
    background: #efd75d 0% 0% no-repeat padding-box;
    opacity: 1;
    border: none;
    color: black;
    width: 261px;
    border-radius: 1px;
    height: 64px;
    font-weight: 700 !important;
    font-size: 20px !important;
  }
  .btn-primary:hover {
    background: white 0% 0% no-repeat padding-box !important;
    box-shadow: none !important;
    color: black !important;
    border: 1px solid #e6e6e6 !important;
  }
}
.location-name {
  float: left;
  padding-top: 23px;
  font-family: "Avenir Next";
  font-size: 16px;
}

.location-container {
  .MuiFormControlLabel-root {
    float: right !important;
    width: 36%;
  }
  .accordion-head {
    font-family: "Avenir Next";
    font-size: 18px;
    background: #efd75d 0% 0% no-repeat padding-box;
    border: none;
    color: black;
  }
}

.fa-spinner {
  position: absolute;
  height: 100px;
  width: 197px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
  z-index: 1;
  font-size: 78px !important;
}
