.login-container {
  .MuiSvgIcon-root {
    height: 40px !important;
    :hover {
      background: none !important;
    }
  }
  .MuiFormControlLabel-label {
    color: white;
  }
  .MuiFormGroup-root {
    display: flow-root;
  }
  .btn-primary:hover {
    background: #40a3bd no-repeat padding-box;
  }
  height: 100%;
  position: absolute;
  .logo-container {
    background: #141414;
    .company-name {
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .company-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        h3 {
          font: Medium 48px/66px Avenir Next !important;
          letter-spacing: 0;
          color: #efd75d;
          text-align: center;
        }
      }
    }
    h3 {
      color: #efd75d;
      margin-top: -190px;
    }
  }
  .login-content {
    height: 100%;
  }
  .login-background {
    background: #1f1f1f !important;
  }
  .login-data {
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .txtfield {
      border-radius: 1px;
      width: 232%;
      padding: 16px;
      font-size: 1em;
      margin-left: -62px;
      margin-top: -14px;
    }
    .forgot-password {
      margin-left: 0px !important;
      color: white;
      font-size: 1em;
      font-weight: 400;
      text-decoration: none;
      font-size: 20px;
      line-height: 35px;
    }
    .btn-login {
      background: #efd75d 0% 0% no-repeat padding-box;
      opacity: 1;
      font-size: 24px;
      font-family: "Avenir Next";
      letter-spacing: 0;
      color: #141414;
      margin-top: 14px;
    }
    .btn-login1 {
      // background: #40a3bd no-repeat padding-box;
      background: url("../images/bankid_btn.png");
      background-position-y: center;
      opacity: 1;
      font-size: 20px !important;
      font-family: "Avenir Next";
      letter-spacing: 0;
      color: white !important;
      margin-top: 16px;
      text-align: end;
      padding-right: 26px;
      :hover {
        background: url("../images/bankid_btn.png");
      }
    }
  }
}

.btn-login2 {
  background: #0191e1;
  opacity: 1;
  font-size: 20px!important;
  font-family: Avenir Next;
  letter-spacing: 0;
  color: #fff!important;
  margin-top: 16px;
  text-align: end;
  height: 64px;
  width: 400px;
  padding-right: 26px;
  position: relative;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding-left: 74px;
}
.btn-login2 span.bankid-icon{
  width: 64px;
  height: 64px;
  background: url("../images/bankid-icon.png");
  background-size: 48px 48px;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  position: absolute;
  left: 14px;
  top:0px;
}
.btn-login2 i.loader {
  position: absolute;
  right: 14px;
  top: 28px;
  width: 12px;
  height: 12px;
}

@media (max-width: 767px) {
  .btn-login2 {
    width: 339px !important;
  }
}
@media (min-width: 538px) and (max-width: 768px) {
  .btn-login2 {
    width: 339px !important;
  }
}
@media (min-width: 320px) and (max-width: 768px){
  // .btn-login2 {
  //     // width:98%;
  //     width: 339px !important;
  // }
  .btn-login2 span.bankid-icon{
      width: 48px;
      height: 48px;
      background-size: 32px 32px;
      top: 6px;
  }
}

.invalid-feedback {
  color: black !important;
  font-size: 17px !important;
}
.forgot-txtfield {
  border-radius: 1px !important;
  width: 172%;
  padding: 16px;
  font-size: 1em;
  margin-left: -62px;
  margin-top: -14px;
  height: 50px !important;
}
.login-admin-header {
  padding-top: 170px;
  font-size: 48px;
}
.login-header {
  font-size: 48px;
  line-height: 55px;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #efd75d !important;
}
.login-error-message-container {
  .invalid-feedback {
    color: #efd75d !important;
    font-size: 17px !important;
  }
}

.person-number-invalid{
  color: #efd75d !important;
  font-size: 17px !important;
  width: 400px;
}
.under-construction{
  display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 700;
}